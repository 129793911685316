import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "32",
  height: "32",
  fill: "none",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g filter=\"url(#a)\"><path fill=\"#fff\" d=\"M4 4h24v24H4z\"></path><path fill=\"#00993C\" d=\"M9.858 13.953H7.634a.192.192 0 0 0-.191.191v3.71c0 .105.086.192.191.192h6.14c.091 0 .18.09.18.18v6.14c0 .105.087.191.192.191h3.71a.192.192 0 0 0 .192-.191v-2.232c0-4.427-3.768-8.181-8.19-8.181Zm14.51 0h-6.14a.191.191 0 0 1-.18-.18v-6.14a.192.192 0 0 0-.192-.192h-3.71a.192.192 0 0 0-.191.191v2.224c0 4.42 3.755 8.19 8.186 8.19h2.227a.192.192 0 0 0 .191-.192v-3.71a.192.192 0 0 0-.191-.191Z\"></path></g><defs><filter id=\"a\" width=\"32\" height=\"32\" x=\"0\" y=\"0\" color-interpolation-filters=\"sRGB\" filterUnits=\"userSpaceOnUse\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feColorMatrix in=\"SourceAlpha\" result=\"hardAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation=\"2\"></feGaussianBlur><feColorMatrix values=\"0 0 0 0 0.0666667 0 0 0 0 0.0588235 0 0 0 0 0.054902 0 0 0 0.16 0\"></feColorMatrix><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_533_27434\"></feBlend><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow_533_27434\" result=\"shape\"></feBlend></filter></defs>", 2)
  ])))
}
export default { render: render }