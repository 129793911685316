import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "34",
  height: "33",
  fill: "none",
  viewBox: "0 0 34 33"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "m19.39 2 3.295 6.67a2 2 0 0 0 1.5 1.095l7.36 1.07a2.665 2.665 0 0 1 1.475 4.545l-5.325 5.195a2 2 0 0 0-.575 1.77l1.26 7.33A2.67 2.67 0 0 1 24.5 32.5l-6.585-3.46a2 2 0 0 0-1.86 0L9.5 32.5a2.67 2.67 0 0 1-3.87-2.81l1.26-7.33a2 2 0 0 0-.575-1.77L1 15.4a2.665 2.665 0 0 1 1.475-4.545l7.36-1.07a2 2 0 0 0 1.5-1.095l3.295-6.67A2.665 2.665 0 0 1 19.39 2Z"
    }, null, -1)
  ])))
}
export default { render: render }